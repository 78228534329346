import React from 'react'
import Link from 'gatsby-link'
import './style.scss'
import Img from "gatsby-image"

import {
  Card,
  CardBody,
  CardFooter,
  Container,
  Col,
  Row
} from 'reactstrap'

import {
  Button
} from '../../components'

class SiteProductVendor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowScrollTop: 0,
    }
  }

  render() {
    const products = this.props.data.remark.products
    const orderByCategories = this.props.orderByCategories
    const arroseurs = []
    const programmateurs = []
    const sondes = []
    const valves = []
    const autres = []
    const allProductsList = []
    products.forEach((data) => {
      const path = data.product.frontmatter.path
      const title = data.product.frontmatter.title
      const productSlug = path.split('/')[3]
      const category = productSlug && productSlug.substring(0, productSlug.indexOf("-"))
      const product = <Col key={Math.random()} lg={3} md={6} xs={12}>
        <Card className="card-pricing">
          <p className="card-category">{title}</p>
          <CardBody>
            <Img style={{maxHeight: 250, minHeight: 250}} imgStyle={{maxHeight: 250, minHeight: 250, objectFit: "contain"}} fluid={data.product.frontmatter.coverImage.childImageSharp.fluid} alt={title} />
          </CardBody>
          <CardFooter>
            <Link to={path}>
              <Button color="primary" round>
                Voir le produit
              </Button>
            </Link>
          </CardFooter>
        </Card>
      </Col>

      if (orderByCategories && category === 'arroseur') {
        arroseurs.push(product)
      }
      if (orderByCategories && category === 'programmateur') {
        programmateurs.push(product)
      }
      if (orderByCategories && category === 'sonde') {
        sondes.push(product)
      }
      if (orderByCategories && category === 'valve') {
        valves.push(product)
      }
      if (orderByCategories && (category !== 'arroseur' && category !== 'programmateur' && category !== 'sonde' && category !== 'valve')) {
        autres.push(product)
      }
      if (!orderByCategories) {
        allProductsList.push(product)
      }
    })

    if (orderByCategories) {
      return (
        <Container>
          <h2>Arroseurs</h2>
          <Row>{arroseurs}</Row>
          <h2>Programmateurs</h2>
          <Row>{programmateurs}</Row>
          <h2>Sondes</h2>
          <Row>{sondes}</Row>
          <h2>Valves</h2>
          <Row>{valves}</Row>
          <h2>Autres</h2>
          <Row>{autres}</Row>
        </Container>
      )
    } else {
      return (
        <Container>
          <Row>{allProductsList}</Row>
        </Container>
      )
    } 
  }
}

export default SiteProductVendor
